import React from "react";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";
import ReactPaginate from "react-paginate";

const ReactPagination = (props) => {
  const { totalLength, itemsPerPage, handlePageClick } = props;

  const handleClick = (selectedPage) => {
    handlePageClick(selectedPage);
  };
  return (
    <ReactPaginate
      nextLabel={
        <span className="flex items-center gap-1">
          Next <HiArrowRight />
        </span>
      }
      onPageChange={handleClick}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={Math.ceil(totalLength / itemsPerPage)}
      previousLabel={
        <span className="flex items-center gap-1">
          <HiArrowLeft /> Previous
        </span>
      }
      pageClassName="text-gray-500 hover:text-gray-900"
      pageLinkClassName="page-link"
      previousClassName="text-gray-500 hover:bg-violet-400 hover:text-white  px-3 py-1 rounded-md"
      previousLinkClassName="page-link"
      nextClassName="bg-purple py-1 px-3 hover:bg-violet-400 text-white rounded-md w-fit"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="flex gap-4 items-center"
      activeClassName="bg-gray-200 px-3 py-1 rounded-md text-purple font-semibold"
      renderOnZeroPageCount={null}
    />
  );
};

export default ReactPagination;
