import { cva } from "class-variance-authority";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import "../../../global.css";
const textStyles = cva("w-full", {
  variants: {
    emphasis: {
      low: "text-gray-600 font-light",
    },
    size: {
      h1: "text-h1",
      h2: "text-h2",
      h3: "text-h3",
      h4: "text-h4",
      h5: "text-h5",
      b1: "text-b1",
      b2: "text-b2",
      b3: "text-b3",
      b4: "text-b4",
      c1: "text-c1",
      c2: "text-c2",
    },
    weight: {
      thin: "font-thin",
      extralight: "font-extralight",
      light: "font-light",
      normal: "font-normal",
      medium: "font-medium",
      semibold: "font-semibold",
      bold: "font-bold",
      extrabold: "font-extrabold",
      black: "font-black",
    },
    align: {
      left: "text-left",
      center: "text-center",
      right: "text-right",
    },
    italic: {
      true: "italic",
    },
    underline: {
      true: "underline underline-offset-2",
    },
    color: {
      // Add color variants
      pink: "text-pink",
      orange: "text-orange",
      green: "text-green",
      white: "text-white",
      "gray-50": "text-gray-50",
      "gray-100": "text-gray-100",
      "gray-200": "text-gray-200",
      "gray-300": "text-gray-300",
      "gray-400": "text-gray-400",
      "gray-500": "text-gray-500",
      "gray-600": "text-gray-600",
      "gray-700": "text-gray-700",
      "gray-800": "text-gray-800",
      "gray-900": "text-gray-900",
      polly: "text-polly",
      purple: "text-purple",
      "violet-50": "text-violet-50",
      "violet-100": "text-violet-100",
      "violet-200": "text-violet-200",
      "violet-300": "text-violet-300",
      "violet-400": "text-violet-400",
      "violet-500": "text-violet-500",
      blue: "text-blue",
      black: "text-black",
    },
  },
  defaultVariants: {
    size: "base",
    align: "left",
  },
});

const Text = forwardRef(
  (
    {
      as,
      align,
      size,
      emphasis,
      italic,
      underline,
      weight,
      className,
      children,
      color,
      ...props
    },
    ref
  ) => {
    const Component = as || "span";

    const textStyle = textStyles({
      size,
      weight,
      emphasis,
      italic,
      underline,
      align,
      className,
    });

    // Add color class if color prop is provided
    const colorClass = color ? `text-${color}` : "";

    return (
      <Component className={`${textStyle} ${colorClass}`} ref={ref} {...props}>
        {" "}
        {/* Include color class */}
        {children}
      </Component>
    );
  }
);

Text.propTypes = {
  as: PropTypes.elementType,
  size: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "b1",
    "b2",
    "b3",
    "b4",
    "c1",
    "c2",
  ]),
  weight: PropTypes.oneOf([
    "thin",
    "extralight",
    "light",
    "normal",
    "medium",
    "semibold",
    "bold",
    "extrabold",
    "black",
  ]),
  color: PropTypes.oneOf([
    "pink",
    "orange",
    "green",
    "white",
    "gray-50",
    "gray-100",
    "gray-200",
    "gray-300",
    "gray-400",
    "gray-500",
    "gray-600",
    "gray-700",
    "gray-800",
    "gray-900",
    "polly",
    "purple",
    "indigo",
    "blue",
    "black",
  ]),
  italic: PropTypes.bool,
  underline: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Text.defaultProps = {
  as: "h1",
  variant: "primary",
  size: "h1",
  weight: "medium",
  color: "gray-700",
  italic: false,
  underline: false,
  className: "custom-class",
  children: "app.hyno.co Typography",
};

export default Text;
