import { useState, useRef, useEffect } from 'react';
import { IoChevronDown } from "react-icons/io5";
import { MdOutlineJoinInner } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";

var dataOfCountries = [
    { "country": "Afghanistan", "timezone": "Asia/Kabul" },
    { "country": "Albania", "timezone": "Europe/Tirane" },
    { "country": "Algeria", "timezone": "Africa/Algiers" },
    { "country": "Andorra", "timezone": "Europe/Andorra" },
    { "country": "Angola", "timezone": "Africa/Luanda" },
    { "country": "Antigua and Barbuda", "timezone": "America/Antigua" },
    { "country": "Argentina", "timezone": "America/Argentina/Buenos_Aires" },
    { "country": "Armenia", "timezone": "Asia/Yerevan" },
    { "country": "Australia", "timezone": "Australia/Sydney" },
    { "country": "Austria", "timezone": "Europe/Vienna" },
    { "country": "Azerbaijan", "timezone": "Asia/Baku" },
    { "country": "Bahamas", "timezone": "America/Nassau" },
    { "country": "Bahrain", "timezone": "Asia/Bahrain" },
    { "country": "Bangladesh", "timezone": "Asia/Dhaka" },
    { "country": "Barbados", "timezone": "America/Barbados" },
    { "country": "Belarus", "timezone": "Europe/Minsk" },
    { "country": "Belgium", "timezone": "Europe/Brussels" },
    { "country": "Belize", "timezone": "America/Belize" },
    { "country": "Benin", "timezone": "Africa/Porto-Novo" },
    { "country": "Bhutan", "timezone": "Asia/Thimphu" },
    { "country": "Bolivia", "timezone": "America/La_Paz" },
    { "country": "Bosnia and Herzegovina", "timezone": "Europe/Sarajevo" },
    { "country": "Botswana", "timezone": "Africa/Gaborone" },
    { "country": "Brazil", "timezone": "America/Sao_Paulo" },
    { "country": "Brunei", "timezone": "Asia/Brunei" },
    { "country": "Bulgaria", "timezone": "Europe/Sofia" },
    { "country": "Burkina Faso", "timezone": "Africa/Ouagadougou" },
    { "country": "Burundi", "timezone": "Africa/Bujumbura" },
    { "country": "Cabo Verde", "timezone": "Atlantic/Cape_Verde" },
    { "country": "Cambodia", "timezone": "Asia/Phnom_Penh" },
    { "country": "Cameroon", "timezone": "Africa/Douala" },
    { "country": "Canada", "timezone": "America/Toronto" },
    { "country": "Central African Republic", "timezone": "Africa/Bangui" },
    { "country": "Chad", "timezone": "Africa/Ndjamena" },
    { "country": "Chile", "timezone": "America/Santiago" },
    { "country": "China", "timezone": "Asia/Shanghai" },
    { "country": "Colombia", "timezone": "America/Bogota" },
    { "country": "Comoros", "timezone": "Indian/Comoro" },
    { "country": "Costa Rica", "timezone": "America/Costa_Rica" },
    { "country": "Croatia", "timezone": "Europe/Zagreb" },
    { "country": "Cuba", "timezone": "America/Havana" },
    { "country": "Cyprus", "timezone": "Asia/Nicosia" },
    { "country": "Czech Republic", "timezone": "Europe/Prague" },
    { "country": "Democratic Republic of the Congo", "timezone": "Africa/Kinshasa" },
    { "country": "Denmark", "timezone": "Europe/Copenhagen" },
    { "country": "Djibouti", "timezone": "Africa/Djibouti" },
    { "country": "Dominica", "timezone": "America/Dominica" },
    { "country": "Dominican Republic", "timezone": "America/Santo_Domingo" },
    { "country": "East Timor", "timezone": "Asia/Dili" },
    { "country": "Ecuador", "timezone": "America/Guayaquil" },
    { "country": "Egypt", "timezone": "Africa/Cairo" },
    { "country": "El Salvador", "timezone": "America/El_Salvador" },
    { "country": "Equatorial Guinea", "timezone": "Africa/Malabo" },
    { "country": "Eritrea", "timezone": "Africa/Asmara" },
    { "country": "Estonia", "timezone": "Europe/Tallinn" },
    { "country": "Eswatini", "timezone": "Africa/Mbabane" },
    { "country": "Ethiopia", "timezone": "Africa/Addis_Ababa" },
    { "country": "Fiji", "timezone": "Pacific/Fiji" },
    { "country": "Finland", "timezone": "Europe/Helsinki" },
    { "country": "France", "timezone": "Europe/Paris" },
    { "country": "Gabon", "timezone": "Africa/Libreville" },
    { "country": "Gambia", "timezone": "Africa/Banjul" },
    { "country": "Georgia", "timezone": "Asia/Tbilisi" },
    { "country": "Germany", "timezone": "Europe/Berlin" },
    { "country": "Ghana", "timezone": "Africa/Accra" },
    { "country": "Greece", "timezone": "Europe/Athens" },
    { "country": "Grenada", "timezone": "America/Grenada" },
    { "country": "Guatemala", "timezone": "America/Guatemala" },
    { "country": "Guinea", "timezone": "Africa/Conakry" },
    { "country": "Guinea-Bissau", "timezone": "Africa/Bissau" },
    { "country": "Guyana", "timezone": "America/Guyana" },
    { "country": "Haiti", "timezone": "America/Port-au-Prince" },
    { "country": "Honduras", "timezone": "America/Tegucigalpa" },
    { "country": "Hungary", "timezone": "Europe/Budapest" },
    { "country": "Iceland", "timezone": "Atlantic/Reykjavik" },
    { "country": "India", "timezone": "Asia/Kolkata" },
    { "country": "Indonesia", "timezone": "Asia/Jakarta" },
    { "country": "Iran", "timezone": "Asia/Tehran" },
    { "country": "Iraq", "timezone": "Asia/Baghdad" },
    { "country": "Ireland", "timezone": "Europe/Dublin" },
    { "country": "Israel", "timezone": "Asia/Jerusalem" },
    { "country": "Italy", "timezone": "Europe/Rome" },
    { "country": "Ivory Coast", "timezone": "Africa/Abidjan" },
    { "country": "Jamaica", "timezone": "America/Jamaica" },
    { "country": "Japan", "timezone": "Asia/Tokyo" },
    { "country": "Jordan", "timezone": "Asia/Amman" },
    { "country": "Kazakhstan", "timezone": "Asia/Almaty" },
    { "country": "Kenya", "timezone": "Africa/Nairobi" },
    { "country": "Kiribati", "timezone": "Pacific/Tarawa" },
    { "country": "Kosovo", "timezone": "Europe/Belgrade" },
    { "country": "Kuwait", "timezone": "Asia/Kuwait" },
    { "country": "Kyrgyzstan", "timezone": "Asia/Bishkek" },
    { "country": "Laos", "timezone": "Asia/Vientiane" },
    { "country": "Latvia", "timezone": "Europe/Riga" },
    { "country": "Lebanon", "timezone": "Asia/Beirut" },
    { "country": "Lesotho", "timezone": "Africa/Maseru" },
    { "country": "Liberia", "timezone": "Africa/Monrovia" },
    { "country": "Libya", "timezone": "Africa/Tripoli" },
    { "country": "Liechtenstein", "timezone": "Europe/Vaduz" },
    { "country": "Lithuania", "timezone": "Europe/Vilnius" },
    { "country": "Luxembourg", "timezone": "Europe/Luxembourg" },
    { "country": "Madagascar", "timezone": "Indian/Antananarivo" },
    { "country": "Malawi", "timezone": "Africa/Blantyre" },
    { "country": "Malaysia", "timezone": "Asia/Kuala_Lumpur" },
    { "country": "Maldives", "timezone": "Indian/Maldives" },
    { "country": "Mali", "timezone": "Africa/Bamako" },
    { "country": "Malta", "timezone": "Europe/Malta" },
    { "country": "Marshall Islands", "timezone": "Pacific/Majuro" },
    { "country": "Mauritania", "timezone": "Africa/Nouakchott" },
    { "country": "Mauritius", "timezone": "Indian/Mauritius" },
    { "country": "Mexico", "timezone": "America/Mexico_City" },
    { "country": "Micronesia", "timezone": "Pacific/Chuuk" },
    { "country": "Moldova", "timezone": "Europe/Chisinau" },
    { "country": "Monaco", "timezone": "Europe/Monaco" },
    { "country": "Mongolia", "timezone": "Asia/Ulaanbaatar" },
    { "country": "Montenegro", "timezone": "Europe/Podgorica" },
    { "country": "Morocco", "timezone": "Africa/Casablanca" },
    { "country": "Mozambique", "timezone": "Africa/Maputo" },
    { "country": "Myanmar", "timezone": "Asia/Yangon" },
    { "country": "Namibia", "timezone": "Africa/Windhoek" },
    { "country": "Nauru", "timezone": "Pacific/Nauru" },
    { "country": "Nepal", "timezone": "Asia/Kathmandu" },
    { "country": "Netherlands", "timezone": "Europe/Amsterdam" },
    { "country": "New Zealand", "timezone": "Pacific/Auckland" },
    { "country": "Nicaragua", "timezone": "America/Managua" },
    { "country": "Niger", "timezone": "Africa/Niamey" },
    { "country": "Nigeria", "timezone": "Africa/Lagos" },
    { "country": "North Korea", "timezone": "Asia/Pyongyang" },
    { "country": "Pakistan", "timezone": "Asia/Karachi" },
    { "country": "Palau", "timezone": "Pacific/Palau" },
    { "country": "Panama", "timezone": "America/Panama" },
    { "country": "Papua New Guinea", "timezone": "Pacific/Port_Moresby" },
    { "country": "Paraguay", "timezone": "America/Asuncion" },
    { "country": "Peru", "timezone": "America/Lima" },
    { "country": "Philippines", "timezone": "Asia/Manila" },
    { "country": "Poland", "timezone": "Europe/Warsaw" },
    { "country": "Portugal", "timezone": "Europe/Lisbon" },
    { "country": "Qatar", "timezone": "Asia/Qatar" },
    { "country": "Republic of the Congo", "timezone": "Africa/Brazzaville" },
    { "country": "Romania", "timezone": "Europe/Bucharest" },
    { "country": "Russia", "timezone": "Europe/Moscow" },
    { "country": "Rwanda", "timezone": "Africa/Kigali" },
    { "country": "Saint Kitts and Nevis", "timezone": "America/St_Kitts" },
    { "country": "Saint Lucia", "timezone": "America/St_Lucia" },
    { "country": "Saint Vincent and the Grenadines", "timezone": "America/St_Vincent" },
    { "country": "Samoa", "timezone": "Pacific/Apia" },
    { "country": "San Marino", "timezone": "Europe/San_Marino" },
    { "country": "Sao Tome and Principe", "timezone": "Africa/Sao_Tome" },
    { "country": "Saudi Arabia", "timezone": "Asia/Riyadh" },
    { "country": "Senegal", "timezone": "Africa/Dakar" },
    { "country": "Serbia", "timezone": "Europe/Belgrade" },
    { "country": "Seychelles", "timezone": "Indian/Mahe" },
    { "country": "Sierra Leone", "timezone": "Africa/Freetown" },
    { "country": "Singapore", "timezone": "Asia/Singapore" },
    { "country": "Slovakia", "timezone": "Europe/Bratislava" },
    { "country": "Slovenia", "timezone": "Europe/Ljubljana" },
    { "country": "Solomon Islands", "timezone": "Pacific/Honiara" },
    { "country": "Somalia", "timezone": "Africa/Mogadishu" },
    { "country": "South Africa", "timezone": "Africa/Johannesburg" },
    { "country": "South Korea", "timezone": "Asia/Seoul" },
    { "country": "South Sudan", "timezone": "Africa/Juba" },
    { "country": "Spain", "timezone": "Europe/Madrid" },
    { "country": "Sri Lanka", "timezone": "Asia/Colombo" },
    { "country": "Sudan", "timezone": "Africa/Khartoum" },
    { "country": "Suriname", "timezone": "America/Paramaribo" },
    { "country": "Sweden", "timezone": "Europe/Stockholm" },
    { "country": "Switzerland", "timezone": "Europe/Zurich" },
    { "country": "Syria", "timezone": "Asia/Damascus" },
    { "country": "Taiwan", "timezone": "Asia/Taipei" },
    { "country": "Tajikistan", "timezone": "Asia/Dushanbe" },
    { "country": "Tanzania", "timezone": "Africa/Dar_es_Salaam" },
    { "country": "Thailand", "timezone": "Asia/Bangkok" },
    { "country": "Togo", "timezone": "Africa/Lome" },
    { "country": "Tonga", "timezone": "Pacific/Tongatapu" },
    { "country": "Trinidad and Tobago", "timezone": "America/Port_of_Spain" },
    { "country": "Tunisia", "timezone": "Africa/Tunis" },
    { "country": "Turkey", "timezone": "Europe/Istanbul" },
    { "country": "Turkmenistan", "timezone": "Asia/Ashgabat" },
    { "country": "Tuvalu", "timezone": "Pacific/Funafuti" },
    { "country": "Uganda", "timezone": "Africa/Kampala" },
    { "country": "Ukraine", "timezone": "Europe/Kiev" },
    { "country": "United Arab Emirates", "timezone": "Asia/Dubai" },
    { "country": "United Kingdom", "timezone": "Europe/London" },
    { "country": "United States of America", "timezone": "America/New_York" },
    { "country": "Uruguay", "timezone": "America/Montevideo" },
    { "country": "Uzbekistan", "timezone": "Asia/Samarkand" },
    { "country": "Vanuatu", "timezone": "Pacific/Efate" },
    { "country": "Vatican City", "timezone": "Europe/Vatican" },
    { "country": "Venezuela", "timezone": "America/Caracas" },
    { "country": "Vietnam", "timezone": "Asia/Ho_Chi_Minh" },
    { "country": "Yemen", "timezone": "Asia/Aden" },
    { "country": "Zambia", "timezone": "Africa/Lusaka" },
    { "country": "Zimbabwe", "timezone": "Africa/Harare" }
];




const OverlapTime = () => {
    const defaultCountry = dataOfCountries[0].timezone; //default country is South Sudan
    const [selectedCountry, setSelectedCountry] = useState(defaultCountry);  //default country is South Sudan
    const [workingOverlapTime, setWorkingOverlapTime] = useState(9);


    // Refs for timelineBar
    const timelineBarRef = useRef(null);

    var workStartTime = 9;
    var workEndTime = 19;

    var overlapStartTime = 0;
    var overlapEndTime = 0;
    var workingOverlap = 0

    // Handle country selection
    const handleCountryName = (event) => {
        const selectedCountry = event.target.value;
        setSelectedCountry(selectedCountry);

        // Get the current time in the selected country's time zone
        const istTime = new Date('2024-01-10T03:30:00Z');
        const otherTime = istTime.toLocaleString('en-US', { timeZone: selectedCountry });
        const dateObject = new Date(otherTime);



        // Calculate overlap time based on working hours
        const hours = dateObject.getHours();

        if (hours < workEndTime && workStartTime < hours) {
            workingOverlap = workEndTime - hours;

            setWorkingOverlapTime(workingOverlap);
            overlapStartTime = hours;
            overlapEndTime = hours + workingOverlapTime



        }

        else if (hours > workEndTime) {
            // workingOverlap = 0;
            setWorkingOverlapTime(0)
        }
        else {
            const temp = workStartTime - hours;
            const temp2 = workStartTime + temp;
            const overlapStartTime = workStartTime + temp;
            const overlapEndTime = overlapStartTime + workEndTime - temp2;
            workingOverlap = workStartTime - temp;
            setWorkingOverlapTime(workingOverlap);


        }

    };

    // Apply styles to timelineBar
    useEffect(() => {
        const timelineBar = timelineBarRef.current;
        let leftSpace = workStartTime - workingOverlapTime;
        let barWidth = workEndTime - workStartTime;

        if (workingOverlapTime <= 0) {
            leftSpace = 0;
            barWidth = 0;
        }

        if (timelineBar) {
            timelineBar.style.width = `${barWidth * 10}%`;
            timelineBar.style.marginLeft = `${leftSpace * 10}%`;
        }
    }, [workingOverlapTime]);

    return (
        <div className="py-5 border-b border-gray-200 w-full">
            <p className="font-medium  text-gray-500 mb-1.5">
                Country
            </p>

            <div className='mb-3'>
                <select
                    id="country"
                    name="country"
                    autoComplete="country-name"
                    className="block w-full rounded-md border-0 py-3  px-4 pr-5 text-gray-900  bg-white dark:bg-gray-300 ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-violet-400 sm:leading-6"
                    onChange={handleCountryName}
                    value={selectedCountry}
                >
                    {dataOfCountries.map((item) => (
                        <option key={item.country} value={item.timezone}>{item.country}</option>
                    ))}
                </select>
                {/* <div className='pr-1 w-fit bg-white absolute right-1 top-3.5'>
                    <IoChevronDown />
                </div> */}
            </div>

            <div className='flex gap-2'>
                <MdOutlineJoinInner className='text-purple text-h3' />
                <div className='w-full'>
                    <div className='flex items-center justify-between'>
                        <p className='text-gray-700 font-semibold'>{workingOverlapTime} hrs of overlap</p>
                      
                    </div>
                    <div className="w-full h-7 bg-gray-100 rounded-md mt-2 overflow-hidden">
                        <p className='h-full bg-purple ' id="timelineBar" ref={timelineBarRef}></p>

                    </div>
                    <div className="flex justify-between mt-2 text-gray-500">
                        <p >09:00</p>
                        <p >12:00</p>
                        <p >15:00</p>
                        <p >19:00</p>
                    </div>

                </div>
            </div>





        </div>
    );
}

export default OverlapTime
