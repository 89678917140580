import React, { useState, Fragment, useContext } from "react";
// import { IoChevronForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { Dialog, Transition } from "@headlessui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { XMarkIcon } from "@heroicons/react/24/outline";
// import { IoNotificationsOutline } from "react-icons/io5";
import HowItWorks from "./HowItWorks";
import { AppContext } from "../../AppContext/AppContext";
import clsx from "clsx";

import Button from "../Library/Button";

const Header = ({HeaderSubPart }) => {
  const { handleThemeSwitch, handleSidebar } = useContext(AppContext);
  const [openHIW, setOpenHIW] = useState(false);

  const handleHowItWorks = () => {
    setOpenHIW(!openHIW);
  };

  const jwtToken = true;

  return (
    <>
      <header
        className={clsx("w-full  flex bg-gray-50 justify-between py-4 px-6 sticky top-0 z-10")}
        id="Header"
      >
        <div className="flex items-center">
          {/* <button
            type="button"
            className="p-2 text-gray-700 hover:text-purple  lg:hidden"
            onClick={() => handleSidebar(true)}
          >

            <GiHamburgerMenu className="h-5 w-5" aria-hidden="true" />
          </button> */}

          {HeaderSubPart && <div className="hidden lg:block ">{HeaderSubPart}</div>}
        </div>
        <div className="flex gap-2 items-center">
          {/* <Button type="button" variant="secondary" size="large" weight="bold">
            <IoNotificationsOutline />
          </Button> */}

          <Button
            type="button"
            variant="tertiary"
            onClick={handleHowItWorks}
            fullWidth={false} // or fulwlWidth={false} depending on your requirement
            size="large"
          >
            How it works ?
          </Button>

          <Link to="/contact-us">
            <Button
              type="button"
              variant="primary"
              onClick={handleHowItWorks}
              fullWidth={false} // or fullWidth={false} depending on your requirement
              size="large"
            >
              <FaPhoneAlt />
              Schedule a call
            </Button>
          </Link>
        </div>
      </header>
      <Transition.Root show={openHIW} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenHIW}>
          <div className="fixed inset-0 overflow-hidden  bg-gray-900 bg-opacity-25">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none  fixed inset-y-0 right-0 flex max-w-full pl-0 pt-0">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md bottom-0 ">
                    <div className="flex h-full flex-col   bg-white dark:bg-gray-900 shadow-xl  ">
                      <div className="flex-1 px-4 py-6 sm:px-6 overflow-y-scroll scrollable_element ">
                        <div className="flex items-start justify-between  ">
                          <div>
                            <HowItWorks />
                          </div>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative -m-2 p-2 text-gray-600 hover:text-gray-900 dark:text-gray-200 dark:hover:text-white"
                              onClick={() => setOpenHIW(false)}
                            >
                              <span className="absolute -inset-0.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="px-4 pt-4 pb-5 dark:bg-gray-700 pl-10 sm:pl-14">
                        <div className="flex text-center sm:ml-2">
                          <Link
                            
                            to="/contact-us"
                          >
                            <Button variant="secondary" size="large">Get a call back</Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Header;
