import { createContext, useState, useEffect } from "react";
export const AppContext = createContext();


export const AppProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false)

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);



  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const handleSidebar=(value)=>{
    setMobileSidebarOpen(value)

  }

  return (
    <AppContext.Provider value={{handleThemeSwitch,theme,handleSidebar,mobileSidebarOpen }}>
      <div>{children}</div>
    </AppContext.Provider>
  );
}