import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AppProvider } from "./AppContext/AppContext";
import Home from "./Pages/Home";
import clsx from "clsx";
import Login from "./Pages/SignIn";
import Contact from "./Pages/Contact-us";
import Sidebar from "./Components/UI/Sidebar";
import NotFound from './Pages/NotFound';
import Developer from './Pages/Developer';
import BaseURL from "./BaseURL";
import PasswordReset from './Pages/PasswordReset';
import SignUp from "./Pages/SignUp";
import Dashboard from './Pages/Dashboard.jsx';
import OnboardedProfiles from './Pages/OnboardedProfiles.jsx';
import OnboardedProfileDetails from "./Pages/OnboardedDetails.jsx";
import Invoice from './Pages/Invoice.jsx';
import InvoiceDetails from "./Pages/InvoiceDetails.jsx";
import Requested from "./Pages/Requested.jsx";
import Profile from "./Pages/Profile.jsx";

const App = () => {
  // const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [isLoader, setIsLoader] = useState('');
  const [communityData, setCommunityData] = useState([]);





  const handleMobileSidebar = (value) => {
    // setMobileSidebarOpen(value);
  };

  // Function to fetch data from the API
  const getAllCandidatesDetails = async () => {
    // Accessing the API key from environment variables
    const APIKey = process.env.REACT_APP_RECRUITCRM_API_KEY;
  


    setIsLoader(true);
    const url = `${BaseURL}?sort_by=updatedon&sort_order=desc`;
    const options = {
      method: 'GET',
      headers: { Accept: 'application/json', Authorization: `Bearer ${APIKey}` }
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setCommunityData(data.data);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getAllCandidatesDetails();
  }, []);

  const jwtToken = false;

  return (
    <AppProvider>
      <Router>
        <div className="bg-gray-50 dark:bg-gray-900 flex w-full">
          <AppContent
            jwtToken={jwtToken}
            hideSidebarPaths={['/login', '/signup', '/password_reset', '/not-found']}
            handleMobileSidebar={handleMobileSidebar}
            isLoader={isLoader}
            communityData={communityData}
          />
        </div>
      </Router>
    </AppProvider>
  );
};

const AppContent = ({ jwtToken, hideSidebarPaths, handleMobileSidebar, isLoader, communityData }) => {
  const { pathname } = useLocation();

  const hideSidebar = hideSidebarPaths.some(path => pathname.includes(path));

  return (
    <>

      {/* {(!hideSidebar) && <Sidebar />} */}
      <div className={clsx({
        "w-full h-screen overflow-scroll": jwtToken === true,
        "container m-auto": jwtToken === false,
      })}>
        <Routes>
          <Route
            exact
            path="/"
            element={<Home handleMobileSidebar={handleMobileSidebar} isLoader={isLoader} communityData={communityData} />}
          />

          <Route exact path="/:slug" element={<Developer />} />
          <Route exact path="/contact-us" element={<Contact />} />

          {
            /*
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/onboarded" element={<OnboardedProfiles />} />
            <Route exact path="/onboarded/:slug" element={<OnboardedProfileDetails />} />
            <Route exact path="/invoice" element={<Invoice />} />
            <Route exact path="/invoice/:slug" element={<InvoiceDetails />} />
            <Route exact path="/request" element={<Requested />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/password_reset" element={<PasswordReset />} />
            */
          }


          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
