import React, { useState, useEffect, useMemo } from "react";
import Header from "../Components/UI/Header";
import Loader from "../Components/UI/Loader";
import Button from "../Components/Library/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MoonLoader } from "react-spinners";
import { LuMail } from "react-icons/lu";
import { BsPerson } from "react-icons/bs";
import { MdOutlineApartment } from "react-icons/md";
import { TbWorldSearch } from "react-icons/tb";
import MyTextArea from "../Components/Forms/MyTextArea";
import { FaIdCardClip } from "react-icons/fa6";
import MyTextInput from "../Components/Forms/MyTextInput";
import { FaPhoneAlt } from "react-icons/fa";
import BaseURL from "../BaseURL";

const Profile = () => {
  const UserDetails = useMemo(() => ({
    name: "Leslie Alexander",
    position: "CEO",
    avatar: "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    about: "My experience and formal education have aided me in developing my advertising skill set and allowed me to work for five large corporations.",
    contact: "5879834873",
    mailId: "alexanderleslie@ronc.co.in",
    company: "Ronc.inc",
    website: "www.bronc.inc.in",
  }), []);

  const [isLoader, setIsLoader] = useState(true);
  const [userData, setUserData] = useState(UserDetails);
  const [successMessage, setSuccessMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // Simulate data fetching
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  }, []);

  const handleSubmit = (values) => {
    
    setIsLoader(true);
    fetch("", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then(response => response.json())
      .then(data => {
        setSuccessMessage(data.inlineMessage);
        setUserData(values);
        setIsLoader(false);
        setIsEditing(false);
      })
      .catch(error => {
        setIsLoader(false);
        setSuccessMessage(error.toString());
        console.error(error);
      });
  };

  const HeaderSubPart = useMemo(() => (
    <h2 className="text-h4 font-semibold text-gray-900">Profile</h2>
  ), []);

  return (
    <>
      {isLoader ? (
        <div className="h-screen w-full flex items-center">
          <Loader />
        </div>
      ) : (
        <div>
          <Header HeaderSubPart={HeaderSubPart} />
          <div className="h-[132px] border  bg-[url('https://img.freepik.com/free-photo/close-up-modern-office-buildings-shanghai_1359-637.jpg')]"></div>
          <div className="flex flex-col items-center p-6">
            <div className="w-full sm:w-[584px]">
              <div className="flex items-center justify-between -mt-6 w-full">
                <div className="flex items-center gap-5">
                  <img
                    className="inline-block h-[138px] w-[138px] rounded-full ring-4 ring-white shadow-lg -mt-10"
                    src={userData.avatar}
                    alt={userData.name}
                  />
                  <div>
                    <h1 className="text-h3 font-bold text-gray-900">{userData.name}</h1>
                    <p className="text-b4 font-medium text-gray-500 mt-1">{userData.position}at {userData.company}</p>
                  </div>
                </div>
                <div>
                  <Button variant="secondary" size="large" onClick={() => setIsEditing(true)}>
                    <FaPhoneAlt />
                    Edit Profile
                  </Button>
                </div>
              </div>
              <Formik
                initialValues={userData}
                validationSchema={Yup.object({
                  name: Yup.string().required("* Required"),
                  about: Yup.string().required("* Required"),
                  contact: Yup.string().required("* Required").matches(/^[0-9]*$/, "* Phone number must contain only digits").min(10, "* Phone number must be at least 10 digits").max(15, "* Phone number must be at most 15 digits"),
                  mailId: Yup.string().email("Invalid email address").required("* Required"),
                  company: Yup.string().max(20, "Must be 20 characters or less").required("* Required"),
                  position: Yup.string().max(20, "Must be 20 characters or less").required("* Required"),
                  website: Yup.string().max(20, "Must be 20 characters or less").required("* Required"),
                })}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="w-full">
                    <div className={`p-6 bg-white shadow-md rounded-lg mt-6 ${isEditing ? 'border border-purple' : ''}`}>
                      <h2 className="text-h5 font-semibold text-gray-700">About me</h2>
                      <MyTextArea
                        label="Enter your company’s Mail Id"
                        name="about"
                        type="textarea"
                        placeholder="Type here..."
                        disabled={!isEditing}
                      />
                    </div>
                    <div className={`p-6 bg-white shadow-md rounded-lg mt-6 ${isEditing ? 'border border-purple' : ''}`}>
                      <h2 className="text-h5 font-semibold text-gray-700">Personal details</h2>
                      <MyTextInput
                        label="Name"
                        name="name"
                        type="text"
                        placeholder="Full Name"
                        icon={BsPerson}
                        disabled={!isEditing}
                      />
                      <MyTextInput
                        label="Contact No."
                        name="contact"
                        type="tel"
                        placeholder="eg. +91 5879834873"
                        icon={FaPhoneAlt}
                        disabled={!isEditing}
                      />
                      <MyTextInput
                        label="Company Mail Id"
                        name="mailId"
                        type="email"
                        placeholder="example@mail.com"
                        icon={LuMail}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className={`p-6 bg-white shadow-md rounded-lg mt-6 ${isEditing ? 'border border-purple' : ''}`}>
                      <h2 className="text-h5 font-semibold text-gray-700">Your company details</h2>
                      <MyTextInput
                        label="Company"
                        name="company"
                        type="text"
                        placeholder="Company"
                        icon={MdOutlineApartment}
                        disabled={!isEditing}
                      />
                      <MyTextInput
                        label="Position"
                        name="position"
                        type="text"
                        placeholder="Position"
                        icon={FaIdCardClip}
                        disabled={!isEditing}
                      />
                      <MyTextInput
                        label="Company’s Website"
                        name="website"
                        type="text"
                        placeholder="www.company.com"
                        icon={TbWorldSearch}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="mt-8">
                      <p className="font-semibold mb-2">{successMessage}</p>
                      <div className="flex gap-3 flex-wrap">
                        {isEditing && (
                          <Button type="submit" variant="secondary" size="large">
                            Save changes
                          </Button>
                        )}
                      </div>
                      {isSubmitting && (
                        <Button
                          type="button"
                          variant="loading"
                          disabled
                          fullWidth={true}
                          size="large"
                          className="px-10"
                        >
                          <MoonLoader size={14} aria-label="Loading Spinner" data-testid="loader" color="black" />
                          Loading
                        </Button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
