import React from "react";
import PropTypes from "prop-types";

function Button({
  variant = "primary",
  size = "medium",
  fullWidth = false,
  className: customClassName,
  children,
  ...rest
}) {
  const intents = {
    primary:
      "bg-gray-900 text-gray-200 hover:text-gray-50 shadow-sm focus:text-white",
    secondary:
      "bg-purple text-white hover:bg-violet-400 shadow-sm focus:bg-violet-500",
    tertiary:
      "text-gray-700 border bg-white  border-gray-200 dark:hover:bg-gray-700 dark:text-gray-200 hover:border-gray-400 focus:border-gray-700",
    link: "text-purple font-bold underline ",
    loading: "bg-gray-500 bg-opacity-90 w-24 text-violet-50",
    danger:
      "bg-orange text-white hover:bg-violet-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-white dark:focus:ring-offset-black focus:ring-offset-1",
  };
  const sizes = {
    small: "h-6 px-2 text-b4",
    medium: "h-8 px-2 text-b4",
    large: "h-10 px-5 text-b4",
    xl: "h-12 px-5 text-b4",
  };
  const fullWidthClass = fullWidth ? "w-full" : "";

  const defaultClassName = `flex items-center justify-center gap-2 rounded-lg font-medium  disabled:opacity-60 disabled:pointer-events-none  ${intents[variant]} ${sizes[size]} ${fullWidthClass}`;
  const className = customClassName
    ? `${defaultClassName} ${customClassName}`
    : defaultClassName;

  return (
    <button className={className} {...rest}>
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["primary", "secondary", "link", "loading"]),
  size: PropTypes.oneOf(["small", "medium", "large", "xl"]),
  fullWidth: PropTypes.bool,
  className: PropTypes.string, // New prop for custom class
};

Button.defaultProps = {
  variant: "primary",
  size: "medium",
  fullWidth: false,
};

export default Button;
