import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import Header from "../Components/UI/Header";
import { MdElectricBolt } from "react-icons/md";
import { BsFillBuildingsFill } from "react-icons/bs";
import { RiShareBoxLine } from "react-icons/ri";
import Loader from "../Components/UI/Loader";
import clsx from "clsx";
import OverlapTime from "../Components/UI/OverlapTime";
import { MdOutlinePlace } from "react-icons/md";
import { FiDownload } from "react-icons/fi";
import Button from "../Components/Library/Button";
import BaseURL from "../BaseURL";
import { HiOutlineLanguage } from "react-icons/hi2";
import { IoChevronForward } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Text from "../Components/Library/Heading";
import MyTextInput from "../Components/Forms/MyTextInput";
import { MdOutlineApartment } from "react-icons/md";

import { BsPerson } from "react-icons/bs";

import { LuMail } from "react-icons/lu";
import * as Yup from "yup";
import { MoonLoader } from "react-spinners";




const CandidateDetails = () => {
  const [showMore, setShowMore] = useState(false);
  const [isLoader, setIsLoader] = useState("");
  const [devDetails, setDevDetails] = useState([]);
  const [techSkillsList, setTechSkillsList] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [step, setStep] = useState(1);
  const [isReport, setIsReport] = useState(false);
  const [reportLoader, setReportLoader] = useState(false)
  const { slug } = useParams();


  const APIKey = process.env.REACT_APP_RECRUITCRM_API_KEY;
  useEffect(() => {
    // Fetch candidate details and work experience when component mounts
    getDeveloperDetails();
  }, []);

  const getDeveloperDetails = async () => {
    setIsLoader(true); // Set loading state to true when fetching data

    const urls = [`${BaseURL}/${slug}`, `${BaseURL}/${slug}/work-history`];

    try {
      const responses = await Promise.all(
        urls.map((url) =>
          fetch(url, {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${APIKey}`,
            },
          })
        )
      );

      const data = await Promise.all(
        responses.map((response) => response.json())
      );
      setIsLoader(false); // Set loading state to false when data is fetched successfully


      const [details, workHistory] = data;
 


      setDevDetails(details);

      const startIndex = 19; // Start index of the range (inclusive)
      const endIndex = 24; // End index of the range (exclusive)

      const techSkills = [];

      details.custom_fields
        .slice(startIndex, endIndex)
        .forEach((skillGroup) => {
          if (skillGroup.value !== null) {
            const skillEntries = skillGroup.value.split(",");
            skillEntries.forEach((entry) => {
              const [skill, stars, yoe] = entry.split("-");
              techSkills.push({ skill, stars, yoe });
            });
          }
        });

      setTechSkillsList(techSkills);
      setWorkExperience(details.work_history);
    } catch (error) {
      setIsLoader(false); // Set loading state to false if there's an error
      console.error("Error fetching data:", error);
    }
  };

  // Empty dependency array ensures that this effect runs only once when the component mounts

  const WorkExperienceWrapper = (props) => {
    const { experienceDetails, length, index } = props;

    return (
      <div className="mt-3">
        <div className="flex gap-3">
          <div className="bg-gray-200 w-6 h-6 rounded-full flex justify-center items-center">
            <div className="bg-white w-3  h-3 rounded-full border-4 border-purple"></div>
          </div>
          <p className="text-purple font-medium text-b4 flex items-center gap-2 ">
            {experienceDetails.work_company_name}{" "}
          </p>
        </div>

        <div
          className={clsx("ml-3 mt-2 pl-6 pb-7", {
            "": index === length - 1,
            "border-l border-gray-300": index !== length - 1,
          })}
        >
          <div className="flex items-center justify-between gap-5">
            <h2 className="text-gray-700 font-medium flex-shrink-0 text-b4">
              {experienceDetails.title}
            </h2>
            {/* <div className="w-full h-px bg-gray-200"></div> */}
            <p className="text-gray-500 font-medium flex-shrink-0 text-b4">
              {experienceDetails.project_year}
            </p>
          </div>
          <p className="text-gray-700 mt-3 text-b4">
            {experienceDetails.work_description}
          </p>
        </div>
      </div>
    );
  };
  const ProjectsWrapper = (props) => {
    const { projectDetails, length, index } = props;

    return (
      <div className="mt-3">
        <div className="flex gap-3">
          <div className="bg-gray-200 w-6 h-6 rounded-full flex justify-center items-center">
            <div className="bg-white w-3  h-3 rounded-full border-4 border-purple"></div>
          </div>
          <a
            href={projectDetails.projectLink}
            target="_blank"
            className="text-purple font-medium text-b4 flex items-center gap-2 "
            rel="noreferrer"
          >
            {projectDetails.projectName} <RiShareBoxLine />{" "}
          </a>
        </div>

        <div
          className={clsx("ml-3 mt-2 pl-6 pb-7", {
            "": index === length - 1,
            "border-l border-gray-300": index !== length - 1,
          })}
        >
          <div className="flex items-center justify-between gap-5">
            <h2 className="text-gray-700 font-medium text-b4 flex-shrink-0">
              {projectDetails.skills}
            </h2>
          </div>
          <p className="text-gray-700 mt-3 text-b4">{projectDetails.about}</p>
        </div>
      </div>
    );
  };

  const downloadInterviewReport = () => {
    setIsReport(true)
  };

  const handleViewStatus = () => {
    setIsReport(!isReport);
  };

  const handleSubmit = (values, { resetForm }) => {
    setReportLoader(true);
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/40226375/e52d883d-c07b-4f79-a241-8d8b363d527c";

    var currentTime = new Date().toISOString();
    var requirementDetails = {
      submittedAt: currentTime, // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
      fields: [
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: values.fullName,
        },

        {
          objectTypeId: "0-1",
          name: "email",
          value: values.email,
        },

        {
          objectTypeId: "0-1",
          name: "company",
          value: values.company,
        },
      ],
      context: {
        hutk: "82db6b47358f1d76c65d5c9f6fb4f834", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: "https://www.app.hyno.co/contact-us",
        pageName: `Contact us`,
      },
      legalConsentOptions: {
        consent: {
          // Include this object when GDPR options are enabled
          consentToProcess: true,
          text: "I agree to allow Example Company to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Example Company.",
            },
          ],
        },
      },
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requirementDetails),
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 400) {
          return response.text();
        } else if (response.status === 403) {
          return response.text();
        } else if (response.status === 404) {
          return response.text();
        }
      })
      .then(function (data) {
        setReportLoader(false);
        const pdfFilePath = devDetails.custom_fields[0].value;
        const link = document.createElement("a");
        link.href = pdfFilePath;
        link.target = "_blank";
        link.setAttribute("download", "filename.pdf");
        link.click();
        resetForm();
        setIsReport(false)
      })
      .catch(function (error) {
        setReportLoader(false);
        console.error(error);
      });
  };
  const handleShowMoreSkills = () => {
    setShowMore(!showMore);
  };
  const RenderAboutDev = (props) => {
    const { description } = props;
    // Function to strip HTML tags from the provided HTML content
    const stripHtmlTags = (html) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || "";
    };

    // Call stripHtmlTags function to remove HTML tags from description
    const plainTextDescription = stripHtmlTags(description);

    return (
      <div className="text-gray-700 mt-3 text-b4">{plainTextDescription}</div>
    );
  };






  const profileImage =
    devDetails.avatar === null
      ? "https://e7.pngegg.com/pngimages/981/645/png-clipart-default-profile-united-states-computer-icons-desktop-free-high-quality-person-icon-miscellaneous-silhouette-thumbnail.png"
      : devDetails.avatar;

  const HeaderSubPart = () => (
    <div>
      <h2 className="text-h4 font-semibold text-gray-900">
        Developers Pool
      </h2>
      <div className="flex items-center gap-2">
        <h2 className="text-b4  text-gray-500 flex items-center gap-2">
          Developers Pool <IoChevronForward />
        </h2>
        <h2 className="text-b2 font-semibold text-gray-700">{devDetails.first_name}</h2>
      </div>
    </div>
  );

  const jwtToken = false;
  return (
    <>
      {isLoader ? (
        <div className="h-screen w-full flex items-center">
          <Loader />
        </div>
      ) : (
        <div>
          <Header HeaderSubPart={<HeaderSubPart />} />

          <div className=" pb-16">
            <div className="bg-gray-100 px-6 py-4  sticky top-0 z-10">
              <div className="flex items-center gap-4 flex-wrap">
                <img
                  src={profileImage}
                  alt={`${devDetails.first_name}`}
                  className="w-20 h-20 rounded-full object-cover border-4 border-white shadow-sm "
                />
                <div>
                  <h1 className="text-gray-900  text-h4 font-semibold">
                    {devDetails.first_name} {devDetails.last_name}
                  </h1>
                  <p className="text-gray-500 font-medium ">
                    {devDetails.position}
                  </p>
                  <div className="mt-2 flex gap-2 flex-wrap">
                    <p className="bg-gray-200 w-fit px-2 py-1 rounded-full text-purple font-medium text-c2 flex items-center gap-1">
                      <MdElectricBolt /> {devDetails.work_ex_year}+ years of
                      exp.
                    </p>
                    <p className="bg-gray-200 w-fit px-2 py-1 rounded-full text-purple font-medium text-c2 flex items-center gap-1">
                      <BsFillBuildingsFill /> {devDetails.position}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-6 flex flex-wrap">
              <div className="w-full lg:w-4/6 pr-6 xl:pr-24">
                <h2 className="text-gray-700 font-bold mt-9 text-h5">
                  About developer
                </h2>
                <RenderAboutDev description={devDetails.candidate_summary} />

                {workExperience.length > 0 ? (
                  <div className="mt-14">
                    <h2 className="text-gray-700 font-bold mb-2 text-h5">
                      Work Experience
                    </h2>
                    {workExperience.length > 0 &&
                      workExperience.map((eachExp, _index) => (
                        <WorkExperienceWrapper
                          experienceDetails={eachExp}
                          key={_index}
                          index={_index}
                          length={workExperience.length}
                        />
                      ))}
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="mt-14">
                  <h2 className="text-gray-700 font-bold mb-2 text-h5">
                    Projects
                  </h2>

                  {BasicDetailsData.prjoectsData.map((eachProj, _index) => (
                    <ProjectsWrapper
                      projectDetails={eachProj}
                      key={_index}
                      index={_index}
                      length={BasicDetailsData.prjoectsData.length}
                    />
                  ))}
                </div> */}
                <div className="mt-14">
                  <h2 className="text-gray-700 font-bold mb-2 text-h5">
                    Skills
                  </h2>
                  <p className="text-gray-700 mt-2 text-b4 w-full overflow-scroll">
                    {devDetails.skill}
                  </p>
                  {techSkillsList.length > 1 ? (
                    <div className="mt-5 bg-gray-50 border border-gray-200 p-5 rounded-lg flex flex-col gap-4">
                      {techSkillsList
                        .slice(0, showMore ? techSkillsList.length : 4)
                        .map((eachSkill) => (
                          <div
                            className="flex items-center justify-between"
                            key={eachSkill.skill}
                          >
                            <div className="w-96 flex items-center justify-between">
                              <p className="font-medium text-gray-700 text-b4">
                                {eachSkill.skill}
                              </p>
                              <div>
                                {[...Array(5)].map((star, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="star"
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          index < parseInt(eachSkill.stars)
                                            ? "#8B5CF6"
                                            : "#94A3B8",
                                        fontSize: "16px",
                                      }}
                                    >
                                      ★
                                    </span>
                                  );
                                })}
                              </div>
                            </div>

                            <p className="font-medium text-gray-500">
                              {eachSkill.yoe} yrs
                            </p>
                          </div>
                        ))}
                    </div>
                  ) : (
                    ""
                  )}

                  {techSkillsList.length > 4 ? (
                    <button
                      className="mt-8 font-medium text-purple"
                      type="button"
                      onClick={handleShowMoreSkills}
                    >
                      View {showMore ? "less" : "more"} skills
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="w-full lg:w-2/6  h-fit sticky top-32  mt-6 ">
                <div className="shadow-sm rounded-xl p-8 mb-4">
                  {devDetails.city !== "" && devDetails.country !== "" ? (
                    <div className="flex gap-2 border-b border-gray-200 pb-5">
                      <MdOutlinePlace className="text-purple w-6 h-6 mt-1" />

                      <div>
                        <p className="text-gray-700 font-semibold text-h5 ">
                          Profile’s Location
                        </p>
                        <p className="text-gray-500 font-medium text-b4">
                          {devDetails.city !== ""
                            ? `${devDetails.city} , `
                            : ""}
                          {devDetails.country !== ""
                            ? `${devDetails.country}`
                            : ""}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <OverlapTime />
                  {devDetails.language_skills === "" ? (
                    ""
                  ) : (
                    <div className="flex gap-2 border-b border-gray-200 pb-5 mt-4">
                      <HiOutlineLanguage className="text-purple w-6 h-6 mt-1" />

                      <div>
                        <p className="text-gray-700 font-semibold text-h5 ">
                          Languages known
                        </p>
                        <p className="text-gray-500 font-medium text-b4 mt-1">
                          {devDetails.language_skills}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <Button
                    variant="tertiary"
                    type="button"
                    fullWidth={true}
                    size="large"
                    onClick={downloadInterviewReport}
                  >
                    Download Interview Report <FiDownload />
                  </Button>
                </div>
                {/* <Link to="/request">
                  <Button
                    variant="secondary"
                    type="button"
                    fullWidth={true}
                    size="large"
                  // onClick={downloadInterviewReport}
                  >
                    Request profile
                  </Button>
                </Link> */}

                {/* <p className="mt-2 text-gray-500">
                  <strong>Note :</strong> Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Sed sit amet justo ipsum.
                </p> */}
              </div>
            </div>
          </div>
          {isReport ? (
            <main className={clsx('flex flex-col  justify-center  items-center bg-gray-500  w-full bg-opacity-50 fixed  z-20', {
              "h-[calc(100%-5rem)] top-20 lg:-ms-32": jwtToken === true,
              "h-screen top-0  w-screen left-0 ": jwtToken === false,
            })}>

              {step === 1 ? <div className="bg-white p-6 rounded-lg lg:w-[664px]">
                <div className="flex justify-between gap-5">
                  <div>
                    <Text weight="bold" size="h3" color="gray-700" as="h1">
                      Download full interview report
                    </Text>
                    {/* <Text
                      className="mt-2"
                      size="b2"
                      weight="normal"
                      color="gray-500"
                      as="p"
                    >
                      Note : The interview report will be sent to your mail
                    </Text> */}
                  </div>
                  <button className="w-6 h-6 text-gray-700" onClick={handleViewStatus}>
                    <XMarkIcon />
                  </button>
                </div>
                <div className="mt-8">
                  <Formik
                    initialValues={{
                      fullName: "",
                      email: "",
                      company: "",
                    }}
                    validationSchema={Yup.object({
                      fullName: Yup.string()
                        .max(15, "Must be 15 characters or less")
                        .required("* Required"),
                      email: Yup.string()
                        .email("Invalid email address")
                        .required("* Required"),
                      company: Yup.string().required("* Required"),
                    })}
                    onSubmit={handleSubmit}
                  >
                    <Form className="w-full ">
                      <MyTextInput
                        label="Full Name"
                        name="fullName"
                        type="text"
                        placeholder="John Smith"
                        icon={BsPerson}
                      />
                      <MyTextInput
                        label="Mail Id"
                        name="email"
                        type="email"
                        placeholder="atultextmail@mailme.co.in"
                        icon={LuMail}
                      />
                      <MyTextInput
                        label="Company"
                        name="company"
                        type="company"
                        placeholder="Google"
                        icon={MdOutlineApartment}
                      />

                      <div className="mt-8">

                        {reportLoader ? (
                          <Button
                            type="button"
                            variant="loading"
                            disabled
                            fullWidth={true} // or fullWidth={false} depending on your requirement
                            size="large"
                            className="px-10"
                          >
                            <MoonLoader
                              size={14}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                              color="black"
                            />
                            Loading
                          </Button>
                        ) : (
                          <div className="flex gap-3 flex-wrap">
                            <Button
                              type="submit"
                              variant="secondary"
                              size="large"
                              fullWidth={true}
                            >
                              Send
                            </Button>

                          </div>
                        )}
                      </div>
                    </Form>
                  </Formik>

                </div>
              </div> : ""}
              {/* {step === 2 ? <div className="bg-white p-6 rounded-lg">
                <div className="flex justify-end gap-5 items-end">
    
                  <button className="w-6 h-6 text-gray-700" onClick={handleViewStatus}>
                    <XMarkIcon />
                  </button>
                </div>
                <div className="mt-8">
                  <Text weight="bold" size="h3" color="gray-700" as="h1">
                    Interview report has been sent to your
                    mail Id.
                  </Text>

                </div>
              </div> : ""} */}
            </main>
          ) : (
            ""
          )}
        </div >
        
      )}
    </>
  );
};

export default CandidateDetails;
