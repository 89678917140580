import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MyTextInput from "../Components/Forms/MyTextInput";
import MyPhoneInput from "../Components/Forms/MyPhoneInput";
import MultiSelect from "../Components/Forms/MultiSelect";
import MyTextArea from "../Components/Forms/MyTextArea";
import Button from "../Components/Library/Button";
import { Link } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import * as Yup from "yup";
import { BsPerson } from "react-icons/bs";
import { MdOutlineApartment } from "react-icons/md";
import { GoMail } from "react-icons/go";
import { MoonLoader } from "react-spinners";
import Text from "../Components/Library/Heading";
import { GoCheckCircleFill } from "react-icons/go";
import Header from "../Components/UI/Header";
// import BaseURL from "../BaseURL";

const rolesList = [
  {
    label: "AWS",
    value: "AWS",
  },
  {
    label: "PHP",
    value: "PHP",
  },

  {
    label: "Python",
    value: "Python",
  },
  {
    label: "Node Js",
    value: "Node Js",
  },
  {
    label: "Angular",
    value: "Angular",
  },
  {
    label: "Devops",
    value: "Devops",
  },

  {
    label: "Scala",
    value: "Scala",
  },
  {
    label: "Java",
    value: "Java",
  },
  {
    label: "Vue.Js",
    value: "Vue.Js",
  },
  {
    label: "Android",
    value: "Android",
  },
  {
    label: "Go",
    value: "Go",
  },
  {
    label: "React Js",
    value: "React Js",
  },
  {
    label: "Ruby On Rails",
    value: "Ruby On Rails",
  },
  {
    label: "Typescript",
    value: "Typescript",
  },

  {
    label: "React Native",
    value: "React Native",
  },
];
const experienceList = [
  {
    id: "4 - 7 yrs",
    label: "4 - 7 yrs",
    value: "4 - 7 yrs",
    isChecked: false,
  },
  {
    id: "8 - 10 yrs",
    label: "8 - 10 yrs",
    value: "8 - 10 yrs",
    isChecked: false,
  },
  {
    id: "10+ yrs",
    label: "10+ yrs",
    value: "10+ yrs",
    isChecked: false,
  },
];

export default function Contact() {
  const [isLoader, setIsLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    setIsLoader(true);
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/40226375/73779d47-1aac-4965-9047-81acbfdae115";
    var currentTime = new Date().toISOString();
    var requirementDetails = {
      submittedAt: currentTime, // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
      fields: [
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: values.fullName,
        },
        {
          objectTypeId: "0-1",
          name: "phone",
          value: values.phoneNumber,
        },
        {
          objectTypeId: "0-1",
          name: "email",
          value: values.email,
        },

        {
          objectTypeId: "0-1",
          name: "company",
          value: values.company,
        },

        {
          objectTypeId: "0-1",
          name: "city",
          value: values.skillsList.join(","),
        },
        {
          objectTypeId: "0-1",
          name: "state",
          value: values.experience,
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: values.jobDescription,
        },
      ],
      context: {
        hutk: "82db6b47358f1d76c65d5c9f6fb4f834", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: "https://www.app.hyno.co/contact-us",
        pageName: `Contact us`,
      },
      legalConsentOptions: {
        consent: {
          // Include this object when GDPR options are enabled
          consentToProcess: true,
          text: "I agree to allow Example Company to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Example Company.",
            },
          ],
        },
      },
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requirementDetails),
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 400) {
          return response.text();
        } else if (response.status === 403) {
          return response.text();
        } else if (response.status === 404) {
          return response.text();
        }
      })
      .then(function (data) {
        setSuccessMessage(data.inlineMessage);
        setFormSubmitted(true);
        setIsLoader(false);
      })
      .catch(function (error) {
        setIsLoader(false);
        setSuccessMessage(error);
        setFormSubmitted(false);
        console.error(error);
      });
  };

  const handleClose = () => {
    window.location.reload();
  };

  const HeaderSubPart = () => (
    <h2 className="text-h4 font-semibold text-gray-900">Schedule a call</h2>
  );

  return (
    <div className="h-screen">
      {formSubmitted ? (
        <div className="h-screen  w-full  bg-gray-400 bg-opacity-25 flex items-center justify-center p-10 fixed left-0">
          <div className="py-6 px-8 bg-white rounded-lg xl:w-[410px] flex flex-col items-center relative">
            <button className="absolute right-7" onClick={handleClose}>
              <XMarkIcon className="w-6 text-gray-700 hover:text-gray-900 hover:bg-gray-100 rounded-full" />
            </button>
            <GoCheckCircleFill className="text-7xl text-purple" />

            <Text
              color="gray-700"
              as="h2"
              size="h3"
              weight="extrabold"
              className="text-center mt-4"
            >
              Success!
            </Text>
            <Text
              color="gray-500"
              as="p"
              size="b4"
              weight="regular"
              className="text-center mt-2 mb-4"
            >
              Your form has been submitted successfully. Our team will reach out
              to you soon.
            </Text>
            <Link to="/">
              <Button variant="primary" fullWidth={true} size="large">
                Take me to home
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <>
          <Header HeaderSubPart={<HeaderSubPart />} />
          <div className="p-6 w-full  lg:h-[calc(100%-4.5rem)]  ">
            <div className="lg:bg-white rounded-lg lg:px-9 lg:py-9 lg:shadow-sm flex h-full z-1  ">
              <Formik
                initialValues={{
                  fullName: "",
                  company: "",
                  email: "",
                  phoneNumber: "",
                  experience: "",
                  skillsList: [],
                  jobDescription: "",
                }}
                validationSchema={Yup.object({
                  fullName: Yup.string()
                    .max(15, "Must be 15 characters or less")
                    .required("* Required"),

                  company: Yup.string()
                    .max(20, "Must be 20 characters or less")
                    .required("* Required"),

                  email: Yup.string()
                    .email("Invalid email address")
                    .required("* Required"),
                  phoneNumber: Yup.string()
                    .required("* Required")
                    .matches(
                      /^[0-9]*$/,
                      "* Phone number must contain only digits"
                    )
                    .min(10, "* Phone number must be at least 10 digits")
                    .max(15, "* Phone number must be at most 15 digits"),
                  experience: Yup.string().required("* Required"),

                  skillsList: Yup.array().min(
                    1,
                    "* Must select at least one option"
                  ),
                })}
                onSubmit={handleSubmit}
              >
                <Form className="w-full flex lg:flex-col flex-wrap lg:h-full overflow-y-scroll xl:gap-x-20">
                  <Text
                    as="h2"
                    size="h5"
                    weight="semibold"
                    color="gray-900"
                    className="w-full lg:w-5/12"
                  >
                    Personal details.
                  </Text>
                  <div className="w-full lg:w-5/12">
                    <MyTextInput
                      label="Full Name"
                      name="fullName"
                      type="text"
                      placeholder="John Smith"
                      icon={BsPerson}
                    />
                  </div>

                  <div className="w-full lg:w-5/12">
                    <MyTextInput
                      label="Company"
                      name="company"
                      type="text"
                      placeholder="Google"
                      icon={MdOutlineApartment}
                    />
                  </div>

                  <div className="w-full lg:w-5/12">
                    <MyTextInput
                      label="Email Address"
                      name="email"
                      type="email"
                      placeholder="tultextmail@mailme.co.in"
                      icon={GoMail}
                    />
                  </div>

                  <div className="mb-16 w-full lg:w-5/12">
                    <MyPhoneInput
                      label="Contact No"
                      name="phoneNumber"
                      placeholder="+91 5879834873"
                    />
                  </div>

                  <div className="w-full lg:w-5/12 mb-8">
                    <Text
                      as="h2"
                      size="h5"
                      weight="semibold"
                      color="gray-900"
                      className="mb-4"
                    >
                      Professional details.
                    </Text>
                    <Text as="h2" size="h5" weight="medium" color="gray-500">
                      What should be the talent’s Experience ?
                    </Text>
                    <div className="flex items-center gap-6 xl:gap-9 mt-4 flex-wrap">
                      {experienceList.map((experience) => (
                        <label
                          key={experience.value}
                          className="text-gray-500 text-b4 flex items-center gap-2 cursor-pointer"
                        >
                          <Field
                            type="radio"
                            name="experience"
                            value={experience.value}
                            className="w-5 h-5 cursor-pointer"
                          />
                          {experience.label}
                        </label>
                      ))}
                    </div>
                    <ErrorMessage
                      name="experience"
                      component="p"
                      className="text-polly mt-1"
                    />
                  </div>
                  <div className="w-full lg:w-5/12 mb-8">
                    <Text as="h2" size="h5" weight="medium" color="gray-500">
                      What Skills and roles are you looking for ?
                    </Text>
                    <Field
                      name="skillsList"
                      id="skillsList"
                      placeholder="eg. Python, Javascript . . . etc"
                      isMulti={true}
                      component={MultiSelect}
                      options={rolesList}
                    />
                  </div>

                  <div className="w-full lg:w-5/12">
                    <Text as="h2" size="h5" weight="medium" color="gray-500">
                      Do you have a job description ?
                    </Text>

                    <MyTextArea
                      label="Job Description"
                      name="jobDescription"
                      type="textarea"
                      placeholder="Type here..."
                    />
                  </div>

                  <div className="w-full lg:w-5/12 mt-4 ">
                    <p className="font-semibold mb-2 ">{successMessage} </p>
                    {isLoader ? (
                      <Button
                        type="submit"
                        variant="secondary"
                        size="large"
                        className="px-10"
                        disabled
                      >
                        Loading...
                      </Button>
                    ) : (
                      <div className="flex gap-3 flex-wrap">
                        <Button
                          type="submit"
                          variant="secondary"
                          size="large"
                          className="px-10"
                        >
                          Submit form
                        </Button>
                      </div>
                    )}


                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
