import React from 'react'

import SCImage1 from '../../Images/Shortlist_candidates.png';
import './../../global.css';




const HowItWorks = () => {
    return (
        <div className='pl-4 sm:pl-9 h-full '>
            <span className='caption'>Process</span>
            <h2 className="text-h3 text-gray-900 font-bold dark:text-white">How it works?</h2>
            <p className="text-gray-700 text-b2 font-medium  dark:text-gray-300 mt-1">
                has a team of highly-experienced developers who
                can work on

            </p>
            <div className='mt-5'>
                <div className='flex gap-5 mb-6'>
                    <div className='flex flex-col items-center gap-2'>
                        <div className='w-3 h-3 border-4 border-purple rounded-xl mt-1.5'></div>
                        <div className='w-px bg-gray-300 dark:bg-gray-500 h-84'>

                        </div>
                    </div>

                    <div>
                        <h3 className='text-gray-900 text-h5 font-semibold dark:text-white'>1. Gather Requirements</h3>
                        <p className='mt-2 text-b4 text-gray-600 dark:text-gray-300'>Our technical experts will get on a call with you to finalize a job description.
                            A purple cross on a black background, symbolizing spirituality and mourning. </p>
                        <img src={SCImage1} alt='' className='rounded-xl mt-4 max-w-full' />
                    </div>
                </div>
                <div className='flex gap-5 mb-6'>
                    <div className='flex flex-col items-center gap-2'>
                        <div className='w-3 h-3 border-4 border-purple rounded-xl mt-1.5'></div>
                        <div className='w-px bg-gray-300 dark:bg-gray-500 h-84'>

                        </div>
                    </div>

                    <div>
                        <h3 className='text-gray-900 text-h5 font-semibold dark:text-white'>2. Shortlist candidates</h3>
                        <p className='mt-2 text-b4 text-gray-600 dark:text-gray-300'>We Shortlist three developers and schedule interviews. 95% of the time, one of them would get selected.</p>
                        <img src={SCImage1} alt='' className='rounded-xl mt-4 max-w-full' />
                    </div>
                </div>
                <div className='flex gap-5 mb-3'>
                    <div className='flex flex-col items-center gap-2'>
                        <div className='w-3 h-3 border-4 border-purple rounded-xl mt-1.5'></div>
                        {/* <div className='w-px bg-gray-300 dark:bg-gray-500 h-84'>

                        </div> */}
                    </div>

                    <div>
                        <h3 className='text-gray-900 text-h5 font-semibold dark:text-white'>3. Onboarding</h3>
                        <p className='mt-2 text-b4 text-gray-600 dark:text-gray-300'>Onboarding with a 9-month contract signing with Flexibility to replace the candidate without any cost </p>
                        <img src={SCImage1} alt='' className='rounded-xl mt-4 max-w-full' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks
