import React from 'react';
import Select from 'react-select';
import { ErrorMessage } from 'formik';
import { BsPersonGear } from "react-icons/bs";

const MultiSelect = ({
    field,
    form,
    options,
    isMulti = false,
    placeholder = 'Select'
}) => {
    function onChange(selectedOptions) {
        form.setFieldValue(
            field.name,
            selectedOptions ? selectedOptions.map((option) => option.value) : []
        );
    }

    const getValue = () => {
        if (options && field.value) {
            return isMulti
                ? options.filter((option) => field.value.indexOf(option.value) >= 0)
                : options.find((option) => option.value === field.value);
        } else {
            return isMulti ? [] : '';
        }
    };

    return (
        <div className='mt-3 h-10 relative'>
            <BsPersonGear className='absolute top-3 left-2.5 text-gray-500 text-lg' />
            <Select
                className="react-select-container h-full text-gray-700 w-full"
                classNamePrefix="react-select"
                name={field.name}
                value={getValue()}
                onChange={onChange}
                options={options}
                isMulti={isMulti}
                placeholder={placeholder}
                unstyled
            />
            <ErrorMessage name={field.name} component="p" className="text-polly mt-1" />
        </div>
    );
};


export default MultiSelect;