import React, { useState } from 'react';
import { useField } from 'formik';
import {  MdVisibility, MdVisibilityOff } from 'react-icons/md'; // import visibility icons


const MyTextInput = ({ label, icon: Icon, ...props }) => {
    const [field, meta] = useField(props);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='mt-3'>
            <label className='text-gray-500 font-medium' htmlFor={props.id || props.name}>
                {label}
            </label>

            <div className="relative mt-2 rounded-lg">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    {Icon && <Icon className="text-gray-500" />}
                </div>
                <input
                    className={`block w-full rounded-lg outline-none border-0 py-1.5 px-9 h-10 text-gray-700 font-medium ring-1 ring-inset placeholder:text-gray-400 sm:leading-6 focus:ring-2 ring-gray-300 focus:ring-inset focus:ring-violet-400`}
                    {...field} {...props}
                    type={props.type === 'password' && showPassword ? 'text' : props.type}
                />
                {props.type === 'password' && (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={togglePasswordVisibility}>
                        {showPassword ? <MdVisibilityOff className="text-gray-500" /> : <MdVisibility className="text-gray-500" />}
                    </div>
                )}
            </div>

            {meta.touched && meta.error && (
                <p className="text-polly mt-1">{meta.error}</p>
            )}
        </div>
    );
};


export default MyTextInput;
