import React from 'react';
import { useField } from 'formik';

const MyTextArea = ({ children, ...props }) => {
    const [field, meta] = useField({ ...props, type: "textarea" });
    return (
      <div>
        <textarea
          className="block w-full rounded-lg outline-none border-0 p-3  text-gray-700 font-medium ring-1 ring-inset ring-gray-300 min-h-20 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-400 sm:leading-6 mt-3"
          {...field}
          {...props}
        ></textarea>
        {meta.touched && meta.error ? (
          <p className="text-polly mt-1">{meta.error}</p>
        ) : null}
      </div>
    );
  };

export default MyTextArea;