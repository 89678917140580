import { Link } from "react-router-dom";
import Button from "../Components/Library/Button";
import Text from "../Components/Library/Heading";

export default function NotFound() {
  return (
    <main className="flex h-screen justify-center  items-center bg-gary-50 w-full p-6">
      <div className="text-center">
        <Text className="text-center" weight="semibold" size="b2" color="purple" as="p">404</Text>
        <Text className="text-center mt-2" weight="bold" size="h1" color="gray-900" as="h1">Page not found</Text>
        <Text className="text-center mt-4" size="b2" weight="normal" color="gray-600" as="p">Sorry, we couldn’t find the page you’re looking for.</Text>
        <div className="mt-6 flex items-center justify-center gap-x-6">
          <Link to='/'
            href="#"
          >
            <Button
              type="button"
              variant="secondary"
              fullWidth={false} // or fullWidth={false} depending on your requirement
              size="large"> Go back home</Button>

          </Link>
          

        </div>
        
      </div>
    </main>

  )
}
