import React from 'react';
import SyncLoader from "react-spinners/SyncLoader";

const Loader = () => {
  return (
    <div className='w-full flex flex-col items-center p-6'>
      <img src='https://res.cloudinary.com/dav5r5x6r/image/upload/v1710325614/Mask_group_3_x0ubg6.png' alt='' className='mb-4' />
      <SyncLoader

        color="blue"
        size={8}
        aria-label="Loading Spinner"
        data-testid="loader"
      />

    </div>
  )
}

export default Loader
