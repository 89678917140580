import React, { useState, useEffect, Fragment } from "react";
import CommunityCard from "../Components/UI/CommunityCard";
import Header from "../Components/UI/Header";
import { FaCheck } from "react-icons/fa6";
// import { HiArrowRight, HiArrowLeft } from "react-icons/hi";
import { clsx } from "clsx";
// import BaseURL from "../BaseURL";
import Loader from "../Components/UI/Loader";
import ReactPagination from "../Components/UI/ReactPaginate";

import { FaListUl } from "react-icons/fa";
import { TbGridDots } from "react-icons/tb";
import { BsSearch } from "react-icons/bs";
import { IoFilterSharp } from "react-icons/io5";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Button from "../Components/Library/Button";

const SkillsData = [
  {
    label: "AWS",
    id: "AWS",
    isChecked: false,
  },
  {
    label: "PHP",
    id: "PHP",
    isChecked: false,
  },

  {
    label: "Python",
    id: "Python",
    isChecked: false,
  },
  {
    label: "Node Js",
    id: "Node Js",
    isChecked: false,
  },
  {
    label: "Angular",
    id: "Angular",
    isChecked: false,
  },
  {
    label: "Devops",
    id: "Devops",
    isChecked: false,
  },

  {
    label: "Scala",
    id: "Scala",
    isChecked: false,
  },
  {
    label: "Java",
    id: "Java",
    isChecked: false,
  },
  {
    label: "Vue.Js",
    id: "Vue.Js",
    isChecked: false,
  },
  {
    label: "Android",
    id: "Android",
    isChecked: false,
  },
  {
    label: "Go",
    id: "Go",
    isChecked: false,
  },
  {
    label: "React Js",
    id: "React Js",
    isChecked: false,
  },
  {
    label: "Ruby On Rails",
    id: "Ruby On Rails",
    isChecked: false,
  },
  {
    label: "Typescript",
    id: "Typescript",
    isChecked: false,
  },

  {
    label: "React Native",
    id: "React Native",
    isChecked: false,
  },
];

const RolesData = [
  {
    label: "Frontend Engineer",
    id: "Frontend Engineer",
    isChecked: false,
  },
  {
    label: "Backend Engineer",
    id: "Backend Engineer",
    isChecked: false,
  },

  {
    label: "Mobile Engineer",
    id: "Mobile Engineer",
    isChecked: false,
  },

  {
    label: "App Engineer",
    id: "App Engineer",
    isChecked: false,
  },
  {
    label: "Fullstack Engineer",
    id: "Fullstack Engineer",
    isChecked: false,
  },
  {
    label: "Devops Engineer",
    id: "Devops Engineer",
    isChecked: false,
  },
  {
    label: "Product Engineer",
    id: "Product Engineer",
    isChecked: false,
  },
];

const ExperienceData = [
  {
    id: 3,
    label: "3 - 6 yrs",
    isChecked: false,
  },
  {
    id: 7,
    label: "7 - 10 yrs",
    isChecked: false,
  },
  {
    id: 10,
    label: "10+ yrs",
    isChecked: false,
  },
];

const Home = (props) => {
  const { isLoader, communityData, handleMobileSidebar } = props;
  const [gridView, setGridView] = useState(true);
  const [isFilters, setIsFilters] = useState(false);
  const [filterCommunityData, setFilterCommunityData] = useState(communityData);
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  const [skillsList, setSkillsList] = useState(SkillsData);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [rolesList, setRolesList] = useState(RolesData);
  const [roles, setRoles] = useState(null);
  const [experienceList, setExperienceList] = useState(ExperienceData);
  const [experience, setExperience] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const itemsPerPage = 10;

  // Update filterCommunityData when communityData changes
  useEffect(() => {
    setFilterCommunityData(communityData);
  }, [communityData]);

  // Invoke when user clicks to request another page
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    handleMobileSidebar(true);
  };

  // Define a function to receive value from child
  const handleGridNListView = (value) => {
    // Handle the received value here
    setGridView(value);
  };

  const SearchKeys = ["first_name", "last_name", "position", "slug"];

  const FilterDataBySkills = (
    searchQuery,
    experience,
    roles,
    selectedSkills
  ) => {
    var FilteredData = communityData.filter((profile) =>
      SearchKeys.some((key) => profile[key].toLowerCase().includes(searchQuery))
    );
    if (experience !== null) {
      // Filter based on experience if experience is not null
      let filteredDataByExperience = FilteredData.filter((item) => {
        if (item.work_ex_year !== null) {
          if (experience === 10 && item.work_ex_year >= experience) {
            return true;
          } else if (
            item.work_ex_year >= experience &&
            item.work_ex_year <= experience + 3
          ) {
            return true;
          }
        }
        return false;
      });
      FilteredData = filteredDataByExperience;
    }
    if (roles !== undefined && roles !== null && roles.length > 0) {
      const updateRolesData = FilteredData.filter((profile) => {
        return roles.includes(profile.position);
      });
      FilteredData = updateRolesData;
    }
    if (
      selectedSkills !== undefined &&
      selectedSkills !== null &&
      selectedSkills.length > 0
    ) {
      // Filter objectsArray based on whether any skill in each object is included in selectedSkills and the skills array is not null
      const filteredDataBySkills = FilteredData.filter(
        (obj) =>
          obj.skill &&
          obj.skill
            .split(",")
            .some((skill) => selectedSkills.includes(skill.toLowerCase()))
      );

      FilteredData = filteredDataBySkills;
    }

    setCurrentPage(0);
    setFilterCommunityData(FilteredData);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    FilterDataBySkills(query, experience, roles, selectedSkills);
  };
  const handleIsFilters = () => {
    setIsFilters(!isFilters);
  };

  const ApplyFilters = () => {
    FilterDataBySkills(searchQuery, experience, roles, selectedSkills);
    setIsFilters(false);
  };
  const ResetFilters = () => {
    setExperience(null);
    setExperienceList(ExperienceData);

    setRolesList(RolesData);
    setRoles([]);

    setSkillsList(SkillsData);
    setSelectedSkills([]);

    FilterDataBySkills(searchQuery, null, [], []);
    setIsFilters(false);
  };

  const handleChangeSkill = (skill) => {
    let updatedSkillsList = skillsList.map((item) =>
      item.id === skill.id ? { ...item, isChecked: !item.isChecked } : item
    );

    const selectedSkillsList = updatedSkillsList
      .filter((item) => item.isChecked === true)
      .map((item) => item.id.toLowerCase());

    setSelectedSkills(selectedSkillsList);
    setSkillsList(updatedSkillsList);
  };
  const handleChangeRole = (role) => {
    let updatedRolesList = rolesList.map((item) =>
      item.id === role.id ? { ...item, isChecked: !item.isChecked } : item
    );
    const selectedRoles = updatedRolesList
      .filter((item) => item.isChecked === true)
      .map((item) => item.id);

    setRoles(selectedRoles);
    setRolesList(updatedRolesList);
  };
  const handleChangeExp = (id) => {
    // Update the experienceList by toggling the isChecked property of each item
    let updatedExpList = experienceList.map((exp) => ({
      ...exp,
      isChecked: exp.id === id,
    }));
    let updatedSkills = updatedExpList.filter((exp) => exp.isChecked === true);

    setExperience(updatedSkills[0].id);
    setExperienceList(updatedExpList);
  };

  const SkillButton = (props) => {
    const { skill, onChangeSkill } = props;
    return (
      <button
        key={skill.id}
        onClick={() => onChangeSkill(skill)}
        className={clsx(
          "px-3 h-9 rounded-full font-medium flex items-center gap-1",
          {
            "bg-gray-200 text-purple": skill.isChecked === true,
            "text-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 border border-gray-300 dark:border-gray-500":
              skill.isChecked === false,
          }
        )}
      >
        {skill.isChecked ? <FaCheck /> : ""} {skill.label}
      </button>
    );
  };

  const RoleButton = (props) => {
    const { role, onChangeRole } = props; // Destructure onChangeRole from props
    return (
      <button
        onClick={() => onChangeRole(role)}
        className={clsx(
          "px-3 h-9 rounded-full font-medium flex items-center gap-1",
          {
            "bg-gray-200 text-purple": role.isChecked === true,
            "text-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 border border-gray-300 dark:border-gray-500":
              role.isChecked === false,
          }
        )}
      >
        {role.isChecked ? <FaCheck /> : ""} {role.label}
      </button>
    );
  };

  const SkillsFilters = () => {
    return (
      <div className="pl-4 sm:pl-9">
        <h2 className="text-gray-900 font-bold dark:text-white">Filters</h2>

        <div className="mt-4">
          <div className="">
            <h2 className="text-gray-900 font-bold dark:text-white">Skills</h2>
            <div className="flex items-center gap-2 mt-2 flex-wrap">
              {skillsList.map((skill) => (
                <SkillButton
                  skill={skill}
                  key={skill.id}
                  onChangeSkill={handleChangeSkill}
                />
              ))}
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-gray-900 font-bold dark:text-white">Roles</h2>
            <div className="flex items-center gap-3 mt-2 flex-wrap">
              {rolesList.map((role) => (
                <RoleButton
                  role={role}
                  key={role.id}
                  onChangeRole={handleChangeRole}
                />
              ))}
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-gray-900 font-bold dark:text-white">
              Experience
            </h2>
            <div className="flex items-center justify-between mt-3 flex-wrap">
              {experienceList.map((exp) => (
                <label
                  className="flex items-center gap-2 text-gray-700 font-medium cursor-pointer"
                  htmlFor={exp.id}
                  key={exp.id}
                  onChange={() => handleChangeExp(exp.id)}
                >
                  <input
                    type="checkbox"
                    className="h-6 w-6 cursor-pointer"
                    checked={exp.isChecked}
                    id={exp.id}
                  />

                  {exp.label}
                </label>
              ))}

              {/* {rolesList.map((role) => (
                <RoleButton
                  role={role}
                  key={role.id}
                  onChangeRole={handleChangeRole}
                />
              ))} */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Filters = (props) => {
    return (
      <>
        <div className="flex justify-between items-center py-4 px-6 bg-gray-50 dark:bg-gray-900 sticky top-0 w-full">
          <div className="flex items-center  gap-2 w-full">
            <div className="relative rounded-md shadow-none h-10 w-4/6">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <BsSearch className="text-gray-600  dark:text-gray-400" />
              </div>
              <input
                id="search"
                type="search"
                name="search"
                value={searchQuery}
                onChange={handleSearch}
                autoComplete="search"
                placeholder="Search for skill, technology, hashtag etc. "
                required
                className="block w-full h-full rounded-md border-0 py-1.5 pl-9 pr-2 text-gray-900 ring-1 ring-inset ring-gray-200 placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-violet-400 outline-none  sm:leading-6 dark:bg-gray-900 dark:text-gray-200 dark:placeholder:text-gray-400 dark:ring-gray-400"
              />
            </div>
            <div className="flex items-center h-10 border border-grey-200 rounded px-2">
              <button
                className="flex items-center gap-2 dark:text-gray-200"
                onClick={handleIsFilters}
              >
                <IoFilterSharp />
                <span className="hidden sm:block"> Filters</span>
              </button>
              {/* <span className="bg-gray-300 h-4 w-0.5 ml-2"></span>
              <div className="flex items-center px-2">
                <select
                  id="sort"
                  name="sort"
                  className="bg-gray-50 dark:bg-gray-900 dark:text-gray-200 cursor-pointer"
                >
                  <option value="asc">A-Z</option>
                  <option value="desc">Z-A</option>
                </select>
                <label htmlFor="sort" className="dark:text-gray-200">
                  Sort
                </label>
              </div> */}
            </div>
          </div>

          <div className="flex gap-2">
            <div className="flex gap-2 px-2 border border-grey-200 h-10 rounded items-center">
              <button
                type="button"
                className={clsx(
                  "flex items-center text-grey-500  gap-2  p-1.5 rounded",
                  {
                    "bg-gray-200": gridView === true,
                    "bg-none": gridView === false,
                  }
                )}
                onClick={() => handleGridNListView(true)}
              >
                <TbGridDots
                  className={clsx({
                    "text-purple": gridView === true,
                    "text-gray-500": gridView === false,
                  })}
                />
              </button>

              <span className="bg-gray-300 h-4 w-0.5"></span>
              <button
                type="button"
                className={clsx(
                  "flex items-center text-grey-500  gap-2  p-1.5 rounded",
                  {
                    "bg-none": gridView === true,
                    "bg-gray-200": gridView === false,
                  }
                )}
                onClick={() => handleGridNListView(false)}
              >
                <FaListUl
                  className={clsx({
                    "text-purple": gridView === false,
                    "text-gray-500": gridView === true,
                  })}
                />
              </button>
            </div>
          </div>
        </div>
        <Transition.Root show={isFilters} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setIsFilters}>
            <div className="fixed inset-0 overflow-hidden  bg-gray-900 bg-opacity-25">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none  fixed inset-y-0 right-0 flex max-w-full pl-0 pt-0">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md bottom-0 ">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray-900 shadow-xl ">
                        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between">
                            <div>{SkillsFilters()}</div>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="relative -m-2 p-2 text-gray-600 hover:text-gray-900 dark:text-gray-200 dark:hover:text-white"
                                onClick={() => setIsFilters(false)}
                              >
                                <span className="absolute -inset-0.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="px-4 pt-4 pb-5 dark:bg-gray-700 pl-10 sm:pl-14">
                          <div className="flex items-center gap-2 text-center sm:ml-2">
                            <Button
                              variant="secondary"
                              size="large"
                              type="button"
                              onClick={ApplyFilters}
                            >
                              Apply filters
                            </Button>
                            <Button
                              variant="tertiary"
                              size="large"
                              type="button"
                              onClick={ResetFilters}
                            >
                              Reset filters
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  };

  // Calculate the start and end indexes for the current page
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const totalLength = filterCommunityData.length;
  // Get the current page's data
  const currentItems = filterCommunityData.slice(startIndex, endIndex);

  const HeaderSubPart = () => <h2 className="text-h4 font-semibold text-gray-900">Developers pool</h2>;

  return (
    <>
      {isLoader ? (
        <div className="h-screen w-full flex items-center">
          <Loader />
        </div>
      ) : (
        <div>
          <Header HeaderSubPart={<HeaderSubPart />} />
          {Filters()}
          <h2 className="px-6 text-gray-700 font-medium text-b4">
            Showing {filterCommunityData.length}+ profiles
          </h2>
          <div
            className={clsx("mt-4 flex flex-wrap pb-14 px-6", {
              "gap-4": gridView === true,
              "gap-3": gridView === false,
            })}
          >
            {currentItems.map((eachItem) => (
              <CommunityCard
                key={eachItem.slug}
                gridView={gridView}
                community={eachItem}
              />
            ))}
          </div>

          <div className="flex items-center justify-center pb-10 pt-4 w-full overflow-x-scroll px-4">
            <ReactPagination
              handlePageClick={handlePageClick}
              totalLength={totalLength}
              itemsPerPage={itemsPerPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
