import React from 'react';
import { useField } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const MyPhoneInput = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);

    // Extracting necessary values and functions from props
    const { onChange, onBlur, value } = field;
    const { touched, error } = meta;
    const { setValue } = helpers;

    return (
        <div className='w-full h-10 mt-3'>
            <label htmlFor={props.name} className='text-gray-500 font-medium'>{label}</label>
            <PhoneInput
                {...props}
                country={'in'}
                value={value}
                onChange={(phoneNumber) => {
                    setValue(phoneNumber);
                    if (onChange) {
                        onChange(phoneNumber);
                    }
                }}
                onBlur={onBlur}
                inputProps={{
                    required: true,
                }}
                className="w-full h-full mt-2 rounded-lg "
            />

            {touched && error && (
                <p className="text-polly mt-1">* Please enter a valid phone number.</p>
            )}
        </div>
    );
};
export default MyPhoneInput;